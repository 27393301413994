<template>
  <div class="page-body">
    <title-bar title="燃气表入库">
      <el-button type="primary" @click="save()" icon="fa fa-save" :loading="loading">
        保存
      </el-button>
      <el-button icon="fa fa-angle-left" @click="$router.back()">取消</el-button>
    </title-bar>
    <el-form ref="editForm" :model="bill" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="入库日期" prop="dt">
            <el-date-picker type="date" v-model="bill.dt" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="燃气表型号" prop="meterType">
            <code-select v-model="bill.meterType" code-type="meterType"></code-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="班组名称" prop="team">
            <code-select v-model="bill.team" code-type="team"></code-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model.trim="bill.remark" clearable :autosize="{minRows:2, maxRows: 5}" :maxlength="100"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-card>
      <div slot="header" class="card-header">
        <div class="title">燃气表号清单</div>
        <el-form inline :model="queryForm">
          <el-form-item label="是否挂表" prop="hangup">
            <el-select v-model="queryForm.hangup" placeholder="全部" @change="query" clearable>
              <el-option :value="true" label="已挂表"></el-option>
              <el-option :value="false" label="未挂表"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="表号" prop="meterNo">
            <el-input v-model.trim="queryForm.meterNo" placeholder="模糊查询" clearable/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="query">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-upload
                :action="this.globalConfig.apiRoot+'/gas-meter/upload'"
                :data="{billId: this.bill.id || ''}"
                with-credentials
                :show-file-list="false"
                accept=".xls,.xlsx"
                :on-success="onUploadSuccess"
                :on-error="onUploadError"
                :on-progress="()=>{loading = true}"
                style="margin-left: 10px">
              <el-button type="primary" icon="fa fa-upload" :loading="loading">
                导入表号
              </el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <el-table stripe :data="records" :border="true" v-loading="loading">
        <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
        <el-table-column prop="meterNo" label="表号" min-width="130" align="center"/>
        <el-table-column prop="requirementId" label="工单编号" min-width="140" align="center"/>
        <el-table-column prop="flowName" label="办理业务" min-width="80" align="center"/>
        <el-table-column prop="userCode" label="客户编号" min-width="80" align="center"/>
        <el-table-column prop="userName" label="客户名称" min-width="80" align="center"/>
        <el-table-column prop="address" label="用气地址" min-width="200"/>
        <el-table-column prop="userPhoneNum" label="电话号码" min-width="200"/>
        <el-table-column prop="workDate" label="上门时间" min-width="80" align="center"/>
        <el-table-column prop="worker" label="上门师傅" min-width="80" align="center"/>
        <el-table-column prop="edit" label="删除" align="center" width="80" v-slot="{row}">
          <el-button type="text" @click="del(row)" v-if="!row.requirementId">删除</el-button>
        </el-table-column>
      </el-table>
      <table-pagination :page-query="queryForm" :total="total" @change="loadData"></table-pagination>
    </el-card>
  </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import TitleBar from "@/components/base/TitleBar.vue";
import TablePagination from "@/components/base/TablePagination.vue";
import CodeSelect from "@/components/base/CodeSelect.vue";
import QRCode from "qrcode";
export default {
  components: {CodeSelect, TablePagination, TitleBar},
  extends: BaseVue,
  data() {
    return {
      id: null,
      records: [],
      total: 0,
      bill: {
        dt: null
      },
      queryForm: {
        billId: null,
        meterNo: null,
        hangup: null,
        page: 1,
        limit: 10
      },
      rules: {
        // dt: [{required: true, message: "入库日期不能为空", trigger: "change"}],
        // meterType: [{required: true, message: "燃气表型号不能为空", trigger: "change"}],
        // team: [{required: true, message: "领用班组不能为空", trigger: "change"}]
      }
    }
  },
  created() {
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    this.bill.dt = this.formatDate(yesterday);
    this.id = this.$route.query.id;
    if(this.id) {
      this.queryForm.billId = this.id;
      this.getBill();
    }else {
      this.query();
    }
  },
  methods: {
    getBill() {
      this.loading = true;
      this.postForm("/gas-meter-in-bill/getById", {
        id: this.id
      },true).then((dr)=>{
        this.bill = dr.bean || {}
        this.query();
      }).finally(()=>{
        this.loading = false;
      })
    },
    loadData() {
      this.loading = true;
      this.queryForm.billId = this.bill.id || 'temp';
      this.postForm("/gas-meter/list", this.queryForm, true).then((dr) => {
        this.records = dr.rows || [];
        this.total = dr.total || 0;
      }).finally(() => {
        this.loading = false;
      });
    },
    query() {
      this.queryForm.page = 1;
      this.loadData();
    },
    save(){
      this.$refs["editForm"].validate(ok => {
        if (!ok) {
          return;
        }
        this.loading = true;
        this.postForm("/gas-meter-in-bill/save", this.bill,true).then((dr)=>{
          this.$router.back();
        }).finally(()=>{
          this.loading = false;
        });
      });
    },
    del(row) {
      this.$confirm("确定要删除该表吗？","提示", {
        type: "warning"
      }).then(()=>{
        this.loading = true;
        this.postForm("/gas-meter/delete", row,true).then((dr)=>{
          this.loadData();
        }).finally(()=>{
          this.loading = false;
        });
      })
    },
    onUploadSuccess(dr) {
      this.loading = false;
      if(dr.success) {
        this.query();
      } else {
        this.$message.error(dr.msg || '导入失败');
      }
    },
    onUploadError(dr) {
      this.loading = false;
      this.$message.error(dr.msg || "上传文件失败");
    },
  }
}
</script>

<style scoped lang="scss">
.page-body{
  .card-header {
    .el-form-item {
      margin-bottom: 0px;
    }
    .title {
      padding: 10px 0px;
      font-weight: 600;
    }
  }
}
</style>
